import "./LastUpdateInfo.css";
import useLastTimeUpdate from "../utilities/useLastTimeUpdate";
import { useState, useEffect } from "react";
import { InfoIcon } from "../Icons/Icons";

const LastUpdateInfo = () => {
    const lastUpdate = useLastTimeUpdate();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [open, setIsOpen] = useState(!isMobile);
    const handleClick = () => {
        if (isMobile) {
            setIsOpen(v => !v);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return lastUpdate ? (
        <div className={`last-update-wrapper ${open ? "active" : ""}`}>
            <button onClick={handleClick}>
                <InfoIcon />
            </button>
            <div className="info">Last update on {lastUpdate}</div>
        </div>
    ) : null;
};

export default LastUpdateInfo;
