import React from "react";
import "./App.css";
import ChatComponent from "./Component/ChatComponent/ChatComponent";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      styleOptions: {
        emojiSet: true,
        backgroundColor: "var(--bg-gray)",
        hideUploadButton: true,
        avatarBorderRadius: "50%",
        avatarSize: 40,
        botAvatarBackgroundColor: "White",
        botAvatarImage: "Icon.png",
        botAvatarInitials: "IVECO",
        showAvatarInGroup: "status",

        bubbleBackground: "#FFF",
        bubbleBorderColor: "#666666",
        bubbleBorderRadius: "0px 15px 15px 15px",
        bubbleBorderStyle: "solid",
        bubbleBorderWidth: 0,
        bubbleTextColor: "#666666",
        bubbleFromUserBackground: "var(--iveco-blue)",
        bubbleFromUserBorderColor: "#00137F",
        bubbleFromUserBorderRadius: "15px 15px 0px 15px",
        bubbleFromUserBorderWidth: 0,

        bubbleFromUserTextColor: "White",
        messageActivityWordBreak: "break-word",
        timestampColor: "#666666",
        notificationText: "#666666",
        failedConnectivity: "red",
        slowConnectivity: "red",

        sendBoxHeight: 60,
        sendBoxButtonColor: "var(--iveco-blue)",
        sendBoxButtonColorOnDisabled: "#CCC",
        sendBoxButtonColorOnFocus: "var(--iveco-blue)",
        sendBoxButtonColorOnHover: "var(--iveco-blue)",
      },
      sectionOptions: {
        boxShadow: "0px 2px 5px rgba(0,0,0,.2)",
        minWidth: "500px",
        borderRadius: "15px",
        width: "40%",
        height: "30%",
        margin: "auto",
      },
    };
  }

  render() {
    const {
      state: {styleOptions, sectionOptions},
    } = this;

    return (
        <section className="chatWrapper">

          {/* <header>Website page</header> */}
          <div className="react-container">

            <ChatComponent
                styleOptions={styleOptions}
                sectionOptions={sectionOptions}
            />
          </div>
          <div className="disclaimer">
            <p>This chatbot is in development. Please be aware that the answers provided may be inaccurate or outdated
              and should not be considered as official Iveco Group declarations. By using this chatbot, you agree to
              accept full responsibility for its use and any content generated and understand that no liability or
              damages can be claimed as a consequence of such use. Please <a href="https://www.ivecogroup.com/legal/privacy_policy" target="_blank"> click here </a> to learn more.</p>
          </div>
        </section>
    );
  }
}

export default App;
