import axios from "axios";
import { useEffect, useState } from "react";

const useLastTimeUpdate = () => {
  const [lastTimeUpdate, setLastTimeUpdate] = useState("");
  useEffect(() => {
    const getLastTimeUpdate = async () => {
      const res = await axios.post(
        process.env.REACT_APP_BASE_ENDPOINT + "/GetLastContentUpdateForChatbot",
        {
          user: process.env.REACT_APP_CHATBOT_USER_ID,
        },
        { headers: { "Content-Type": "application/json" } }
      );

      const result = res.data.result;
      const date = new Date(result);

      if (!isNaN(date.getTime())) {
        setLastTimeUpdate(date.toLocaleString(navigator.language, { dateStyle: "short" }));
      }
    };

    getLastTimeUpdate();
  }, []);

  return lastTimeUpdate;
};

export default useLastTimeUpdate;
