const RefreshIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="icon icon-tabler icon-tabler-reload"
      viewBox="0 0 24 24"
      style={{color: "var(--iveco-blue)"}}
    >
      <path stroke="none" d="M0 0h24v24H0z"></path>
      <path d="M19.933 13.041a8 8 0 11-9.925-8.788c3.899-1 7.935 1.007 9.425 4.747"></path>
      <path d="M20 4v5h-5"></path>
    </svg>
  );
};

const InfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="icon icon-tabler icon-tabler-info-square-rounded"
      viewBox="0 0 24 24"
    >
      <path stroke="none" d="M0 0h24v24H0z"></path>
      <path d="M12 9h.01M11 12h1v4h1"></path>
      <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9-9 9-9-1.8-9-9 1.8-9 9-9z"></path>
    </svg>
  );
};

const RobotIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={16}
      fill="none"
      {...props}
    >
      <g fill="#fff">
        <path d="M5.438 5.281a.906.906 0 1 1-1.813 0 .906.906 0 0 1 1.813 0ZM9.516 5.281a.906.906 0 1 1-1.813 0 .906.906 0 0 1 1.813 0Z" />
        <path d="M2.945 11.625a2.719 2.719 0 0 0-2.719 2.719.906.906 0 1 0 1.813 0 .906.906 0 0 1 .906-.906h7.25a.906.906 0 0 1 .907.906.906.906 0 1 0 1.812 0 2.72 2.72 0 0 0-2.719-2.719H7.477V9.813h2.492A3.171 3.171 0 0 0 13.14 6.64V3.92A3.172 3.172 0 0 0 9.969.75H3.172A3.171 3.171 0 0 0 0 3.922V6.64a3.172 3.172 0 0 0 3.172 3.171h2.492v1.813H2.945ZM3.172 8a1.36 1.36 0 0 1-1.36-1.36V3.923a1.36 1.36 0 0 1 1.36-1.36h6.797a1.36 1.36 0 0 1 1.36 1.36V6.64A1.36 1.36 0 0 1 9.968 8H3.172Z" />
      </g>
    </svg>
  );
};

const PaperPlaneIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="icon icon-tabler icon-tabler-send"
      viewBox="0 0 24 24"
    >
      <path stroke="none" d="M0 0h24v24H0z"></path>
      <path d="M10 14L21 3M21 3l-6.5 18a.55.55 0 01-1 0L10 14l-7-3.5a.55.55 0 010-1L21 3"></path>
    </svg>
  );
};

const CloseIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      className="icon icon-tabler icon-tabler-x"
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <path d="M18 6 6 18M6 6l12 12" />
    </svg>
  );
};

export {RefreshIcon, InfoIcon, RobotIcon, PaperPlaneIcon, CloseIcon};
